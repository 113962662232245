import React from 'react';
import PromotionsTemplate from '../../../templates/promotions';
import Leaderboard from '../../../components/Leaderboard';
import { silverLeaderboardPromotionData } from '../../../data/promotions';
import config from '../../../config';

const silverLeaderboardPage = ({ location, pageContext }) => (
    <PromotionsTemplate
        pageContext={{ ...pageContext, ...silverLeaderboardPromotionData }}
        location={location}
        component={<Leaderboard id={silverLeaderboardPromotionData.id} selectedTable="step0" leaderboardUrl={config.leaderboard.silverLeaderboardUrl} catalogUrl={config.leaderboard.silverLeaderboardCatalogueUrl} />}
    /> 
);

export default silverLeaderboardPage;
